<template>
  <div
    class="main"
    :class="isWx ? 'main_wx' : ''"
    :style="
      !isIos && !isAndroid && !isWx ? 'max-width: 500px; margin: 0 auto;' : ''
    "
  >
    <!-- <div style="position:absolute; top:0; left:0; padding: 12px 12px; box-sizing: border-box; display: flex;">
      <div style="flex:none; padding-right: 9px;">
        <img style="width:80px; height:25px;" src="./assets/image/logo.png" />
      </div>
      <div style="flex:auto;">
        <div style="font-size: 10px; font-weight: 400; color: #FFFFFF; line-height: 16px;">开发者信息：上海众旦信息科技有限公司<br>
          应用版本：{{version}}<br>
          应用涉及<a style="text-decoration: none; color: #fff;" href="https://h5.aikucun.com/p/gJiemGl0o.html">《用户使用协议》</a>、<a style="text-decoration: none; color: #fff;" href="https://h5.aikucun.com/p/VhgvVjTTo.html">《隐私政策》</a>及<a style="text-decoration: none; color: #fff;" href="https://h5.aikucun.com/p/QRFXlwzdc.html">《应用权限》</a></div>
      </div>
    </div> -->
    <img :src="require('../assets/image/akc_wholesale/1.jpg')"/>

    <img :src="require('../assets/image/akc_wholesale/2.jpg')" />

    <img :src="require('../assets/image/akc_wholesale/3.jpg')" />

    <img :src="require('../assets/image/akc_wholesale/4.jpg')" />

    <div
      class="button_wrap"
      :style="
        !isIos && !isAndroid && !isWx
          ? 'width: 500px; margin: 0 auto; height:80px; padding: 16px 0; box-shadow:0px -1px 6px 0px rgba(213,213,213,0.3);'
          : ''
      "
    >
      <div
        class="open_app"
        :style="
          !isIos && !isAndroid && !isWx
            ? 'font-size: 16px; height:48px;  border-radius:24px; line-height: 44px; left: 16px;'
            : ''
        "
        @click="getUrl('click')"
      >
        我已下载 立即打开
      </div>
      <div
        class="download_app"
        :style="
          !isIos && !isAndroid && !isWx
            ? 'font-size: 16px;  height:48px; border-radius:24px; line-height: 48px;  right: 16px;'
            : ''
        "
        @click="downloadNow()"
      >
        立即下载
      </div>
    </div>
    <div v-if="isWx" class="guide">
      <div class="arrow"></div>
      <div class="text">
        <p>点击右上角</p>
        <p>请选择在<i></i>(浏览器)打开</p>
      </div>
    </div>
  </div>
</template>
<script>
import {
  isIos,
  isAndroid,
  isWxBroser,
  isInOurApp,
  getQueryString,
  openAppIfPossible,
} from "@/assets/js/util.js";
import { tracker } from "@akc/akc-track";
import axios from "axios";

export default {
  name: "guideDownApp",
  data() {
    return {
      isWx: false,
      isIos,
      isAndroid,
      commonProperties: (() => {
        let c = getQueryString("c");
        if (null != c && c.length > 0) {
          return { channel: c };
        }
        return {};
      })(),
      version:''
    };
  },
  created() {
    this.isWx = isWxBroser;
    document.title = "下载爱库存";
    this.getAppVersion()
  },
  mounted() {
    tracker.pageView(this.commonProperties);
    if (isInOurApp) {
      // 爱库存内部不尝试直接打开 App
      return;
    }
    openAppIfPossible();
  },
  methods: {
    getUrl(v) {
      if (v == "click") {
        let ps = this.commonProperties;
        ps.btn_name = "立即打开";
        tracker.track("btn_click", ps);
      }
      openAppIfPossible();
    },
    downloadNow() {
      let ps = this.commonProperties;
      ps.btn_name = "立即下载";
      tracker.track("btn_click", ps);
      let url = 'https://uri6.com/tkio/rQ3aMfa';
      if (isIos) {
        url = 'https://uri6.com/tkio/IjeAn2a';
      }
      window.location.href = url;
    },
    getAppVersion(){
      axios.get(process.env.VUE_APP_ORIGIN_ZUUL + '/aggregation-center-facade/api/app/front/conf/map').then(res=>{
        if(res&&res.data&&res.data.data&&res.data.data.appVersion){
          const appVersion = JSON.parse(res.data.data.appVersion)
          this.version = this.isIos ? appVersion.iOS : appVersion.android
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position:relative;

  &.main_wx {
    overflow: hidden;
  }
  img {
    width: 100%;
    display: block;
    vertical-align: top;
    overflow: hidden;
    pointer-events: none;
  }
  .button_wrap {
    width: 100%;
    height: 80px;
    padding: 16px 0;
    position: fixed;
    bottom: 0;
    background: #fff;
    box-shadow: 0px -1px 6px 0px rgba(213, 213, 213, 0.3);
    box-sizing: border-box;
    & > div {
      text-align: center;
      font-family: PingFangSC-Medium, sans-serif;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      line-height: 48px;
      width: 44.27%;
    }

    .open_app {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translate(0, -50%);
      color: #3D6FFC;
      border: 1px solid #3D6FFC;
      box-sizing: border-box;
    }
    .download_app {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      color: #fff;
      background: linear-gradient(to right, #478DFF, #2E3CF7);
    }
  }
  .guide {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    .arrow {
      width: 55px;
      height: 51px;
      position: absolute;
      top: 10px;
      right: 26px;
      background-image: url("../assets/image/guide.png");
      background-size: 100% 100%;
    }
    .text {
      width: 144px;
      height: 40px;
      position: absolute;
      top: 62px;
      right: 13px;
      p {
        line-height: 20px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        position: relative;
        i {
          width: 12px;
          height: 12px;
          position: relative;
          top: 2.5px;
          display: inline-block;
          background-image: url("../assets/image/akc/global.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
